import { ArrowBack, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, AlertTitle, Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, MenuItem, Modal, Select, Tooltip } from '@mui/material'
import axios from 'axios'
import SoftBox from 'components/SoftBox'
import SoftInput from 'components/SoftInput'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { postSubmit } from 'global/postFunc'
import { serverUrl } from 'global/variable'
import React, { useEffect, useState } from 'react'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'

const Tambah = ({ text, korcam, relawan, vg, deleting, setDeleting, ...props }) => {
    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));

    const [loading, setLoading] = useState(false)

    const [sumberData, setSumberData] = useState('')

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
    };

    const [kecs, setKecs] = useState([]);
    const [desas, setDesas] = useState([]);

    const [kec, setKec] = useState('');
    const [desa, setDesa] = useState('');
    const [nik, setNik] = useState('');
    const [nama, setNama] = useState('');

    useEffect(() => {
        getKec()
        if (vg !== '') {
            console.log('Sumber Data VG');
            setSumberData(vg)
            setKec(relawan.id_kec)
            setDesa(relawan.id_kel)
        }
        else if (relawan !== '') {
            console.log('Sumber Data Relawan');
            setSumberData(relawan)
            setKec(relawan.id_kec)
            setDesa(relawan.id_kel)
        }
        else {
            console.log('Sumber Data Korcam');
            setSumberData(korcam)
            setKec(korcam.id_kec)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const getKec = async () => {
        const kec = await axios.get(serverUrl + '/kecamatan');

        setKecs(kec.data)
    }

    const handleChangeKec = (params) => {
        if (params === '') {
            setKec('')
            setDesa('')
        }
        else {
            setKec(params)
            // getDesa(params)
        }
    };

    useEffect(() => {
        if (kec !== '') {
            getDesa(kec)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kec])

    const getDesa = async (params) => {
        addReq()
        const data = await axios.get(serverUrl + '/desa/' + params);
        setDesas(data.data)
        // console.log(data);
    }

    const handleChangeDesa = (params) => {
        if (params === '') {
            setDesa('')
        }
        else {
            setDesa(params)
        }
    };

    const submit = (event) => {
        event.preventDefault();
        if (nik.length !== 16) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "NIK harus 16 digit",
              });
              return
        }
        let frm = {
            nik: nik,
            nama: nama,
            id_kec: kec,
            id_kel: desa,
            sumber_data_username: sumberData.nik,
            sumber_data_nama: sumberData.nama,
            status: 'DTD',
        }
        setLoading(true)
        addReq()
        postSubmit(
            '/entry/dtd/insert',
            frm,
            decryptedUser,
            afterSubmit,
            (error) => {
                setLoading(false)
                console.log(error);
            }
        )
    }

    const afterSubmit = () => {
        setLoading(false)
        setDeleting(!deleting)
        handleClose()
        setNik('')
        setNama('')
    }

    return (
        <>
            <Button onClick={handleOpen} {...props}>{text}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    overflow: 'scroll',
                }}
            >
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            <CardHeader
                                title={
                                    <SoftTypography variant='h6'>Kembali</SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={handleClose}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            />
                            {
                                loading ?
                                    (
                                        <Grid container spacing={3} py={5} justifyContent="center">
                                            <Grid item>
                                                <Box sx={{ display: 'flex' }}>
                                                    <CircularProgress />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    (
                                        <CardContent component='form' onSubmit={submit}>
                                            <Grid container justifyContent='center' py={2}>
                                                <Grid item xs={12} sm={12} md={9}>
                                                    <Alert severity="error">
                                                        <AlertTitle>Penting</AlertTitle>
                                                        Bagi Pemilih yang tidak ada NIK, maka NIK dikosongkan
                                                    </Alert>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={3} justifyContent="center">
                                                <Grid item xs={12} sm={12} md={10} lg={8}>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Pilih Kecamatan
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        defaultValue=''
                                                        value={kec || ''}
                                                        label="Kecamatan"
                                                        onChange={(event) => handleChangeKec(event.target.value)}
                                                        required
                                                    >
                                                        <MenuItem value=''><em>Pilih Desa</em></MenuItem>
                                                        {
                                                            kecs.map((kec) => (
                                                                <MenuItem key={kec.id_kec} value={kec.id_kec}>{kec.nama_kec}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3} justifyContent="center">
                                                <Grid item xs={12} sm={12} md={10} lg={8}>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Pilih Desa / Kelurahan
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        defaultValue=''
                                                        value={desa || ''}
                                                        label="Desa"
                                                        onChange={(event) => handleChangeDesa(event.target.value)}
                                                        required
                                                    >
                                                        <MenuItem value=''><em>Pilih Desa</em></MenuItem>
                                                        {
                                                            desas.map((des) => (
                                                                <MenuItem key={des.id_desa} value={des.id_desa}>{des.nama_desa}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3} justifyContent="center">
                                                <Grid item xs={12} sm={12} md={10} lg={8}>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            NIK
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftInput
                                                        type="text"
                                                        value={nik || ''}
                                                        onChange={(event) => setNik(event.target.value)}
                                                        autoComplete='off'
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3} justifyContent="center">
                                                <Grid item xs={12} sm={12} md={10} lg={8}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Nama
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            value={nama || ''}
                                                            onChange={(event) => setNama(event.target.value)}
                                                            autoComplete='off'
                                                            required
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                            </Grid>
                                            <Grid container justifyContent='center' pt={3}>
                                                <Grid item xs={10}>
                                                    <Grid container justifyContent='flex-end'>
                                                        <Grid item>
                                                            <SoftBox mb={1} ml={0.5}>
                                                                <LoadingButton
                                                                    loading={loading}
                                                                    loadingPosition="start"
                                                                    startIcon={<Save />}
                                                                    variant="contained"
                                                                    type='submit'
                                                                >
                                                                    Save
                                                                </LoadingButton>
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    )
                            }

                        </Card>
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}

export default Tambah