import { Card, CardMedia, Grid } from '@mui/material'
import SoftAvatar from 'components/SoftAvatar'
import SoftBox from 'components/SoftBox'

import calon from 'assets/images/calon.png'
import bg from "assets/images/bg.png"
import jargon from "assets/images/jargon.png"
import logo from "assets/images/logo512.png"
import { CustomDescrypt } from 'global/func'
import { decodeToken } from 'react-jwt'
import SoftTypography from 'components/SoftTypography'
import userStore from 'store/userStore'

const Headerx = () => {
    const { user } = userStore((state) => ({ user: state.user }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));
    const decodedUser = decryptedUser === '' ? '' : decodeToken(decryptedUser);

    return (
        <SoftBox position="relative" sx={{ paddingTop: '0px !important' }}>
            <SoftBox
                display="grid"
                alignItems="center"
                position="relative"
                minHeight="22rem"
                paddingTop='0px !important'
                // marginTop='-10rem'
                // borderRadius="xl"
                sx={{
                    backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.info.main, 0),
                            rgba(gradients.info.state, 0)
                        )}, url(${bg})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                }}
            >
                <Grid container justifyContent={{ lg: 'flex-start', xs: 'center' }} mt={{ lg: -1.5, xs: -4 }} >
                    <Grid item ml={{ lg: 5 }}  sx={{zIndex: 999}}>
                        <CardMedia
                            src={calon}
                            component="img"
                            title={decodedUser.status}
                            sx={{
                                maxWidth: "100%",
                                height: { lg: '280px', xs: '260px' },
                                margin: 0,
                                // boxShadow: ({ boxShadows: { md } }) => md,
                                objectFit: "cover",
                                objectPosition: "center",
                                zIndex: 990
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent='flex-end' mt={-48} width='100%' display={{ xs: 'none', lg: 'flex' }}>
                    <Grid item mr={10}>
                        <img src={jargon} alt='logo' width='100%' height='200px' style={{ transform: [{ scaleX: -1 }] }} />
                    </Grid>
                </Grid>
            </SoftBox>
            <Card
                sx={{
                    backdropFilter: `saturate(200%) blur(30px)`,
                    backgroundColor: ({ functions: { rgba }, palette: { background } }) => rgba(background.default, 0.8),
                    // backgroundColor: 'white',
                    // opacity: 0.8,
                    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                    position: "relative",
                    mt: -8,
                    mx: 3,
                    py: 2,
                    px: 2,
                }}
            >
                <SoftBox>
                    <Grid container spacing={2} justifyContent='flex-start'>
                        <Grid item xs='auto' >
                            <SoftAvatar
                                src={logo}
                                alt="profile-image"
                                variant="rounded"
                                size="xl"
                                shadow="sm"
                            />
                        </Grid>
                        <Grid item>
                            <SoftBox height="100%" my={1} lineHeight={1} width="100%">
                                <SoftTypography variant="h5" width="100%">
                                    {/* {decryptedUser.role === '2' ? 'Admin' : 'Tim'} */}
                                    {decodedUser.nama || ''}
                                </SoftTypography>
                                <SoftTypography variant="body2" width="100%">
                                    {decodedUser.status || ''}
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                    </Grid>
                </SoftBox>
            </Card>
        </SoftBox>
    )
}

export default Headerx