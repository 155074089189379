import { ArrowBack, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, List, ListItem, ListItemText, ListSubheader, MenuItem, Select, Tooltip } from '@mui/material'
import axios from 'axios'
import SoftBox from 'components/SoftBox'
import SoftInput from 'components/SoftInput'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { postSubmit } from 'global/postFunc'
import { serverUrl } from 'global/variable'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import userStore from 'store/userStore'

const EditKorcam = () => {
    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));
    
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)

    const [desas, setDesas] = useState([])

    const location = useLocation();
    const data = location.state;

    const [formData, setFormData] = useState({
        prevNik: data.nik,
        nik: '',
        nama: '',
        no_hp: '',
        id_kec: data.id_kec,
        nama_kec: data.nama_kec,
        id_kel: '',
        nama_kel: '',
        status: 'Korcam'
    })

    useEffect(() => {
        console.log(formData);
        console.log(data);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    const submit = (event) => {
        event.preventDefault();
        setLoading(true)
        addReq()
        postSubmit(
            '/entry/korcam/update',
            formData,
            decryptedUser,
            afterSubmit,
            (error) => {
                setLoading(false)
                console.log(error);
            }
        )
    }

    const afterSubmit = () => {
        setLoading(false)
        navigate(-1)
    }

    // const handleChangeDesa = (event) => {
    //     if (event.target.value !== '') {
    //         reqDpt(event.target.value)
    //         setDesa(event.target.value)
    //     }
    // };

    useEffect(() => {
        getDesa(data.id_kec)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getDesa = async (params) => {
        const data = await axios.get(serverUrl + '/desa/' + params);
        setDesas(data.data)
        // console.log(data);
    }

    const handleChangeDesa = (event) => {
        if (event.target.value !== '') {
            let resultObject = desas.find(x => x.id_desa === event.target.value)
            // reqDpt(event.target.value)
            setFormData({ ...formData, id_kel: event.target.value, nama_kel: resultObject.nama_desa })
            console.log(resultObject);
        }
    };


    return (
        <SoftBox mb={3}>
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            <CardHeader
                                title={
                                    <SoftTypography variant='h6'>Kembali</SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            />
                            {
                                loading ?
                                    (
                                        <Grid container spacing={3} py={5} justifyContent="center">
                                            <Grid item>
                                                <Box sx={{ display: 'flex' }}>
                                                    <CircularProgress />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    (
                                        <CardContent component='form' onSubmit={submit}>
                                            <Grid container justifyContent='center'>
                                                <Grid item xs={12} sm={12} lg={12}>
                                                    <List sx={{ bgcolor: 'background.paper' }} subheader={
                                                        <ListSubheader sx={{ px: "0" }}>
                                                            <SoftTypography component="label" variant="body2" fontWeight="bold">
                                                                Data Sebelumnya
                                                            </SoftTypography>
                                                        </ListSubheader>
                                                    }>
                                                        <ListItem>
                                                            <ListItemText primary={
                                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                    NIK
                                                                </SoftTypography>
                                                            }
                                                                secondary={
                                                                    <SoftTypography component="label" variant="body2" >
                                                                        {data.nik}
                                                                    </SoftTypography>
                                                                } />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemText primary={
                                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                    Nama
                                                                </SoftTypography>
                                                            }
                                                                secondary={
                                                                    <SoftTypography component="label" variant="body2" >
                                                                        {data.nama}
                                                                    </SoftTypography>
                                                                } />
                                                        </ListItem>
                                                    </List>
                                                </Grid>
                                            </Grid>
                                            <Grid container justifyContent='center' direction='column' mt={3}>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftTypography component="label" variant="body2" fontWeight="bold">
                                                        Isi Data Baru
                                                    </SoftTypography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Kecamatan
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.nama_kec}
                                                            onChange={(event) => setFormData({ ...formData, no_hp: event.target.value })}
                                                            autoComplete='off'
                                                            required
                                                            disabled
                                                            inputProps={{ maxLength: 12 }}
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6} mb={2}>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Alamat
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        defaultValue=''
                                                        value={formData.id_kel}
                                                        label="Desa"
                                                        onChange={handleChangeDesa}
                                                        required
                                                    >
                                                        <MenuItem value=''><em>Pilih Desa</em></MenuItem>
                                                        {
                                                            desas.map((des) => (
                                                                <MenuItem key={des.id_desa} value={des.id_desa}>{des.nama_desa}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                NIK
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.nik}
                                                            onChange={(event) => setFormData({ ...formData, nik: event.target.value })}
                                                            autoComplete='off'
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Nama
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.nama}
                                                            onChange={(event) => setFormData({ ...formData, nama: event.target.value })}
                                                            autoComplete='off'
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                No. HP
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.no_hp}
                                                            onChange={(event) => setFormData({ ...formData, no_hp: event.target.value })}
                                                            autoComplete='off'
                                                            required
                                                            inputProps={{ maxLength: 12 }}
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <Grid container justifyContent='flex-end'>
                                                        <Grid item>
                                                            <SoftBox mb={1} ml={0.5}>
                                                                <LoadingButton
                                                                    loading={loading}
                                                                    loadingPosition="start"
                                                                    startIcon={<Save />}
                                                                    variant="contained"
                                                                    type='submit'
                                                                >
                                                                    Save
                                                                </LoadingButton>
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    )
                            }

                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
    )
}

export default EditKorcam