import { HighchartsReact } from 'highcharts-react-official'
import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import userStore from 'store/userStore';
import { CustomDescrypt } from 'global/func';
import { getDataWithResponse } from 'global/getFunc';
import { useSoftUIController } from 'context';

require("highcharts/modules/map")(Highcharts);

const MapChartPercent = ({ kecamatans = [] }) => {
    const [controller] = useSoftUIController();
    const { darkMode } = controller;

    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));
    const [data, setData] = useState([]);
    // let [mapData, setMapData] = useState([]);
    const [options, setOptions] = useState({})
    // const [kelurahans, setKelurahans] = useState([])


    useEffect(() => {
        let dtx = []
        kecamatans.forEach((kecamatan, index) => {
            // let shp = map.find(item => item.id === kecamatan.id_kec)

            let dt = {
                id: kecamatan.id_kec,
                value: (kecamatan.perolehan/kecamatan.target) * 100,
                perolehan: kecamatan.perolehan,
                dpt: kecamatan.dpt,
                target: kecamatan.target,
                name: kecamatan.nama_kec,
                // drilldown: kecamatan.id_kec,
                path: kecamatan.path
            }
            dtx[index] = dt;
        });
        setData(dtx)
        console.log(dtx);
        // console.log(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kecamatans])

    useEffect(() => {
        setOptions({
            chart: {
                backgroundColor: darkMode ? '#111c44' : '#ffffff',
                // events: {
                //     drilldown: function (e) {
                //         if (!e.seriesOptions) {
                //             // console.log(e);
                //             var chart = this

                //             // Show the spinner
                //             chart.showLoading('Memuat Data'); // Font Awesome spinner
                //             addReq()
                //             getDataWithResponse(
                //                 '/kecamatan/perolehan/'+ e.point.id,
                //                 decryptedUser,
                //                 (response) => {
                //                     console.log(response);
                //                     let dtx = []
                //                     let kelurahans = response;
                //                     // let kels = mapKel[e.point.id]
                //                     kelurahans.forEach((kel, index) => {
                //                         // let shp = kels.find(item => item.id === kel.id_kel)

                //                         let dt = {
                //                             id: kel.id_kel,
                //                             value: kel.perolehan,
                //                             dpt: kel.dpt,
                //                             name: kel.nama_kel,
                //                             path: kel.path
                //                         }
                //                         dtx[index] = dt;
                //                     });
                //                     // setData(dtx)
                //                     console.log(dtx);
                //                     chart.hideLoading();
                //                     clearTimeout();
                //                     chart.addSeriesAsDrilldown(e.point, {
                //                         name: e.point.name,
                //                         animation: true,
                //                         data: dtx,
                //                         borderColor: 'black',
                //                         borderWidth: 0.2,
                //                         dataLabels: {
                //                             enabled: true,
                //                             format: "{point.name}"
                //                         }
                //                     });
                //                 },
                //                 (error) => {
                //                     chart.showLoading(
                //                         'Gagal memuat Data ' + e.point.name
                //                     );
                //                     chart.hideLoading();
                //                 }
                //             );
                //         }

                //         this.setTitle(null, { text: e.point.name });
                //     },
                //     drillup: function () {
                //         this.setTitle(null, { text: "" });
                //     },
                // },
                // animation: false
            },

            title: {
                text: 'Perolehan Suara per Kecamatan'
            },

            subtitle: {
                text: 'Kolaka'
            },

            credits: {
                enabled: false
            },

            // legend: {
            //     title: {
            //         text: 'Population density per km²'
            //     }
            // },

            mapNavigation: {
                enabled: true,
                buttonOptions: {
                    verticalAlign: 'bottom'
                },
                enableMouseWheelZoom: false
            },

            colorAxis: {
                min: 0,
                // minColor: "#E6E7E8",
                // maxColor: "#005645"
            },

            plotOptions: {
                map: {
                    dataLabels: {
                        enabled: true,
                        useHtml: true,
                        // format: '{point.name}'
                        formatter: function () {
                            return (
                                `<p>${this.point.name}</p>`
                            )
                        }
                    }
                },
                series: {
                    animation: false
                },
            },

            tooltip: {
                useHtml: true,
                // pointFormat: '{point.y:,.0f}',
                formatter: function () {
                    return (
                        `<b>${this.point.name}</b></br>
                        Perolehan ${Highcharts.numberFormat(this.point.perolehan, 0, ',', '.')} (${Highcharts.numberFormat(this.point.value, 2, ',', '.')}%)</br>
                        Target ${Highcharts.numberFormat(this.point.target, 0, ',', '.')}`
                    )
                }
            },

            series: [
                {
                    data: data,
                    animation: true,
                    name: "Kolaka",
                    borderColor: 'black',
                    borderWidth: 0.2,
                    dataLabels: {
                        enabled: true,
                        format: "{point.name}"
                    }
                },
                // {
                //     type: "mapline",
                //     data: separators,
                //     color: "silver",
                //     enableMouseTracking: false,
                //     animation: {
                //         duration: 500
                //     }
                // }
            ],

            drilldown: {
                activeDataLabelStyle: {
                    color: "#FFFFFF",
                    textDecoration: "none",
                    textOutline: "1px #000000"
                },
                drillUpButton: {
                    relativeTo: "spacingBox",
                    position: {
                        x: 0,
                        y: 60
                    }
                }
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])
    return (
        <HighchartsReact
            highcharts={Highcharts}
            constructorType={"mapChart"}
            options={options}
        />
    )
}

export default MapChartPercent