import { ArrowBack, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Card, CardContent, CardHeader, Divider, Grid, IconButton, List, ListItem, ListItemText, MenuItem, Select, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import SoftBox from 'components/SoftBox'
import SoftInput from 'components/SoftInput'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { postSubmit } from 'global/postFunc'
import { serverUrl } from 'global/variable'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'

const TambahRelawan = () => {
    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));

    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state;

    const [loading, setLoading] = useState(false)
    const [desas, setDesas] = useState([]);

    const [formData, setFormData] = useState({
        nik: '',
        nama: '',
        no_hp: '',
        id_kec: data.id_kec,
        nama_kec: data.nama_kec,
        id_kel: '',
        nama_kel: '',
        sumber_data_username: data.nik,
        sumber_data_nama: data.nama,
        status: 'Relawan'
    })

    const submit = (event) => {
        event.preventDefault();
        if (formData.nik.length !== 16) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "NIK harus 16 digit",
              });
              return
        }
        setLoading(true)
        addReq()
        postSubmit(
            '/entry/korkel/insert',
            formData,
            decryptedUser,
            afterSubmit,
            (error) => {
                setLoading(false)
                console.log(error);
            }
        )
    }

    const afterSubmit = () => {
        setLoading(false)
        navigate(-1)
    }

    useEffect(() => {
        console.log(data);
        getDesa(data.id_kec)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getDesa = async (params) => {
        const data = await axios.get(serverUrl + '/desa/' + params);
        setDesas(data.data)
        // console.log(data);
    }

    const handleChangeDesa = (event) => {
        if (event.target.value === '') {
            setFormData({ ...formData, id_kel: '', nama_kel: '' })
        }
        else {
            let resultObject = desas.find(x => x.id_desa === event.target.value)
            console.log(resultObject);
            setFormData({ ...formData, id_kel: event.target.value, nama_kel: resultObject.nama_desa })
        }
    };


    useEffect(() => {
        console.log(formData);
    }, [formData])

    return (
        <SoftBox mb={3}>
            <Grid container spacing={3} mt={3} justifyContent='center'>
                <Grid item xs={12} lg={9}>
                    <Card>
                        <CardHeader
                            title={
                                <SoftTypography variant='h6'>Kembali</SoftTypography>
                            }
                            avatar={
                                <Tooltip title="Kembali">
                                    <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                </Tooltip>
                            }
                        />
                        <CardContent>
                            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                <ListItem>
                                    <ListItemText primary={
                                        <Typography variant='subtitle2'>
                                            Kecamatan
                                        </Typography>
                                    } secondary={
                                        <Typography variant='body2'>
                                            {data.nama_kec}
                                        </Typography>
                                    } />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemText primary={
                                        <Typography variant='subtitle2'>
                                            NIK Korcam
                                        </Typography>
                                    } secondary={
                                        <Typography variant='body2'>
                                            {data.nik}
                                        </Typography>
                                    } />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemText primary={
                                        <Typography variant='subtitle2'>
                                            Nama Korcam
                                        </Typography>
                                    } secondary={
                                        <Typography variant='body2'>
                                            {data.nama}
                                        </Typography>
                                    } />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {/* <Grid container justifyContent='center' py={2}>
                <Grid item xs={12} sm={12} md={9}>
                    <Alert severity="error">
                        <AlertTitle>Penting</AlertTitle>
                        Bagi Kordus atau korling yang tidak ada NIK, maka NIK diganti menjadi "kordus_nama_desa" atau "korling_nama_desa". Contoh kordes_lorongi
                    </Alert>
                </Grid>
            </Grid> */}
            <Grid container spacing={3} justifyContent='center' py={2}>
                <Grid item xs={12} sm={12} md={9}>
                    <Card >
                        <CardContent component='form' onSubmit={submit}>
                            <Grid container justifyContent='center' direction='column'>
                                <Grid item xs={12} sm={12} lg={6} mb={2}>
                                    <SoftBox mb={1} ml={0.5}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Desa / Kelurahan
                                        </SoftTypography>
                                    </SoftBox>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue=''
                                        value={formData.id_kel || ''}
                                        label="Desa"
                                        onChange={handleChangeDesa}
                                        required
                                    >
                                        <MenuItem value=''><em>Pilih Desa / Kelurahan</em></MenuItem>
                                        {
                                            desas.map((des) => (
                                                <MenuItem key={des.id_desa} value={des.id_desa}>{des.nama_desa}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <SoftBox mb={2} justifyContent="center">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                NIK
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            type="text"
                                            defaultValue={formData.nik}
                                            onChange={(event) => setFormData({ ...formData, nik: event.target.value })}
                                            autoComplete='off'
                                        />
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <SoftBox mb={2} justifyContent="center">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Nama
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            type="text"
                                            defaultValue={formData.nama}
                                            onChange={(event) => setFormData({ ...formData, nama: event.target.value })}
                                            autoComplete='off'
                                        />
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <SoftBox mb={2} justifyContent="center">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                No. HP
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            type="text"
                                            defaultValue={formData.no_hp}
                                            onChange={(event) => setFormData({ ...formData, no_hp: event.target.value })}
                                            autoComplete='off'
                                            required
                                            inputProps={{ maxLength: 12 }}
                                        />
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <Grid container justifyContent='flex-end'>
                                        <Grid item>
                                            <SoftBox mb={1} ml={0.5}>
                                                <LoadingButton
                                                    loading={loading}
                                                    loadingPosition="start"
                                                    startIcon={<Save />}
                                                    variant="contained"
                                                    type='submit'
                                                >
                                                    Save
                                                </LoadingButton>
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>

                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default TambahRelawan