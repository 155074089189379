import { Card, CardContent, CardHeader, Chip, Grid, Paper, Stack, TableContainer, Typography } from '@mui/material'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { getDataWithResponse } from 'global/getFunc'
import { MaterialReactTable } from 'material-react-table'
import React, { useEffect, useState } from 'react'
import userStore from 'store/userStore'

const Tim = () => {
    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));

    // const [loading, setLoading] = useState(true)
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const requestData = () => {
        addReq()
        getDataWithResponse(
            '/entry/tim',
            decryptedUser,
            setTableData,
            null
        )
    }

    const columns = [
        {
            accessorKey: 'nik',
            header: 'NIK',
        },
        {
            accessorKey: 'nama',
            header: 'Nama',
            Cell: ({ cell }) => {
                switch (cell.row.original.status) {
                    case 'Korcam':
                        return <Chip label={cell.row.original.nama} color='primary' />

                    case 'Relawan':
                        return <Chip label={cell.row.original.nama} color='success' />

                    default:
                        return <Chip label={cell.row.original.nama} color='warning' />
                }
            }
        },
        {
            accessorKey: 'id_kec',
            header: 'ID_KEC'
        },
        {
            accessorKey: 'nama_kec',
            header: 'Kec',
        },
        {
            accessorKey: 'id_kel',
            header: 'ID_KEL'
        },
        {
            accessorKey: 'nama_kel',
            header: 'Kel',
            Cell: ({ cell }) => {
                switch (cell.row.original.status) {
                    case 'Korcam':
                        return ''

                    default:
                        return cell.row.original.nama_kel
                }
            }
        },
        {
            accessorKey: 'no_hp',
            header: 'No. HP',
        },
        {
            accessorKey: 'status',
            header: 'Status',
            Cell: ({ cell }) => {
                switch (cell.row.original.status) {
                    case 'Relawan':
                        return 'Kordus/Korling'

                    default:
                        return cell.row.original.status
                }
            }
        }
    ]

    const columnVisibility = {
        id_kec: false,
        id_kel: false,
    }

    return (
        <SoftBox my={3}>
            <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <Card>
                        <CardHeader
                            title={
                                <SoftTypography color='text'>
                                    Tabel Tim
                                </SoftTypography>
                            }
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <SoftBox mb={1} ml={0.5}>
                                        <Typography component="label" variant="body2" fontWeight="bold">
                                            Ket
                                        </Typography>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} mb={2}>
                                <Grid item>
                                    <Stack direction="row" spacing={1}>
                                        <Chip label="Korcam" color="primary" />
                                        <Chip label="Kordus/Korling" color="success" />
                                        <Chip label="Vote Getter" color="warning" />
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="space-between" >
                                <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                        <MaterialReactTable
                                            data={tableData}
                                            columns={columns}
                                            initialState={{
                                                showColumnFilters: true,
                                                columnVisibility: columnVisibility,
                                            }}
                                            enablePagination={false}
                                            enableRowActions={true}
                                            // onColumnFiltersChange={setColumnFilters} 
                                            positionActionsColumn='first'
                                            displayColumnDefOptions={{
                                                'mrt-row-actions': {
                                                    header: '', //change header text
                                                    size: 50, //make actions column wider
                                                    alignment: 'left'
                                                },
                                            }}
                                            muiTableHeadCellProps={{
                                                sx: {
                                                    fontSize: '0.875rem !important',
                                                }
                                            }}
                                            muiTableBodyCellProps={{
                                                sx: {
                                                    fontWeight: 'normal',
                                                    fontSize: '0.875rem !important',
                                                },
                                            }}
                                            enableExpanding={true}
                                        />
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    );
}

export default Tim