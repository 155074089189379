import { List, ListItemText } from "@mui/material";
import { GoogleMap, InfoWindow, LoadScript, Marker } from "@react-google-maps/api";
import { useState } from "react";

const MapComponent = ({data = []}) => {
    const [infoWindowOpen, setInfoWindowOpen] = useState(false);

    const containerStyle = {
        width: "100%",
        height: "400px",
    }

    const center = {
        lat: -4.002404,
        lng: 122.527102,
    }

    const mapClicked = (event) => {
        console.log(event.latLng.lat(), event.latLng.lng())
    }

    return (
        <LoadScript googleMapsApiKey='AIzaSyBSPnDjyNgyZ-lCwoKg_gkzZaiRLbDc1Ic'>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={12}
                onClick={mapClicked}
                options={{
                    mapTypeControl: true,
                    streetViewControl: false,
                    draggable: true, // make map draggable
                    zoomControlOptions: { position: 9 },
                    keyboardShortcuts: false, // disable keyboard shortcuts
                    scaleControl: true, // allow scale controle
                    scrollwheel: true, // allow scroll wheel
                    //styles: mapsStyle,// change default map styles,
                }}
            >
               <Marker
                    key={0}
                    position={{ lat: parseFloat(data.lat), lng: parseFloat(data.longi) }}
                    label={'Data Center'}
                    draggable={false}
                    onClick={() => setInfoWindowOpen(true)}
                >
                    {infoWindowOpen && (
                        <InfoWindow onCloseClick={() => setInfoWindowOpen(false)}>
                            <List>
                                <ListItemText primary="Data Center" />
                                <ListItemText primary={"Lat:"} secondary={data.lat}/>
                                <ListItemText primary={'Long:'} secondary={data.longi} />
                            </List>
                        </InfoWindow>
                    )}
                </Marker>
            </GoogleMap>
        </LoadScript>
    );
};

export default MapComponent;