import { HighchartsReact } from 'highcharts-react-official'
import React from 'react'
import Highcharts from 'highcharts'

const PieStandar = ({ title, data = [] }) => {

    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
        },
        title: {
            text: title,
            align: 'center'
        },
        tooltip: {
            pointFormat: '<b>{point.y}<br>{point.percentage:.1f}%</b>'
        },
        // accessibility: {
        //     point: {
        //         valueSuffix: '%'
        //     }
        // },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>:<br>{point.y}<br> {point.percentage:.2f} %',
                    // padding: 0,
                    // connectorPadding: 0,
                    // distance: '-30%'
                },
                showInLegend: true
            }
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: true
        },
        series: [{
            name: title,
            colorByPoint: true,
            data: data
        }]
    }
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default PieStandar