/* eslint-disable react/jsx-pascal-case */
import { Delete, Edit, FileDownload, Info, Print } from '@mui/icons-material'
import { Box, Button, IconButton, ListItemIcon, MenuItem } from '@mui/material'
import axios from 'axios';
import { MRT_ShowHideColumnsButton, MRT_ToggleDensePaddingButton, MRT_ToggleFiltersButton, MRT_ToggleFullScreenButton, MRT_ToggleGlobalFilterButton, MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { Link, useLocation } from 'react-router-dom';
import userStore from 'store/userStore';
import Swal from 'sweetalert2';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import { CustomDescrypt } from 'global/func';
import { postDelete } from 'global/postFunc';

const MyTable = ({ data = [], columns, deleting, setDeleting, deleteUrl = null, deleteField, detailUrl = null, editUrl = null, columnVisibility = null, ...props }) => {
    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));

    const pathname = useLocation().pathname;

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        // headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const hapusData = (field, key, url) => {
        console.log(key);
        const frm = {}
        frm[field] = key;
        frm['_method'] = 'DELETE'
        Swal.fire({
            title: 'Hapus Data?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Hapus',
            cancelButtonText: 'Batal',
        }).then((result) => {
            if (result.isConfirmed) {
                addReq()
                postDelete(
                    url,
                    frm,
                    decryptedUser,
                    (res) => {
                        console.log(res);
                        
                        setDeleting(!deleting)
                    },
                    (error) => {
                        console.log(error);
                    }
                )
            }
        })
    }

    const handleExportData = () => {
        csvExporter.generateCsv(data);
    };

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    // const table = useMaterialReactTable({
    //     data,
    //     columns,
    //     initialState: {
    //         showColumnFilters: true,
    //         columnVisibility: columnVisibility,
    //         // pagination: { pageSize: 10, pageIndex:1 }
    //     },
    //     enableRowActions: deleteUrl !== null || detailUrl !== null || editUrl !== null,
    //     // onColumnFiltersChange={setColumnFilters} 
    //     positionActionsColumn: 'first',
    //     displayColumnDefOptions: {
    //         'mrt-row-actions': {
    //             header: '', //change header text
    //             size: 50, //make actions column wider
    //             alignment: 'left'
    //         },
    //     },
    //     renderRowActionMenuItems: ({ row }) => [
    //         detailUrl &&
    //         <MenuItem
    //             key={0}
    //             component={Link} to={pathname + detailUrl} state={row.original}
    //             sx={{ m: 0 }}
    //             disabled={detailUrl === null}
    //         >
    //             <ListItemIcon>
    //                 <Info color={detailUrl ? 'info' : 'disabled'} />
    //             </ListItemIcon>
    //             Detail
    //         </MenuItem>,
    //         editUrl &&
    //         <MenuItem
    //             key={1}
    //             component={Link} to={pathname + editUrl} state={row.original}
    //             sx={{ m: 0 }}
    //             disabled={editUrl === null}
    //         >
    //             <ListItemIcon>
    //                 <Edit color={editUrl ? 'primary' : 'disabled'} />
    //             </ListItemIcon>
    //             Edit
    //         </MenuItem>,
    //         deleteUrl &&
    //         <MenuItem
    //             key={2}
    //             onClick={() => {
    //                 // View profile logic...
    //                 hapusData(deleteField, row.original[deleteField], deleteUrl);
    //             }}
    //             sx={{ m: 0 }}
    //             disabled={deleteUrl === null}
    //         >
    //             <ListItemIcon>
    //                 <Delete color={deleteUrl ? 'error' : 'disabled'} />
    //             </ListItemIcon>
    //             Hapus
    //         </MenuItem>,
    //     ],

    //     muiTablePaginationProps: {
    //         rowsPerPageOptions: [10, 20, 50, { label: 'All', value: data.length }],
    //         showFirstButton: true,
    //         showLastButton: true,
    //     },
    //     renderTopToolbarCustomActions: ({ table }) => (
    //         <Box
    //             sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
    //         >
    //             <Button
    //                 color="primary"
    //                 //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
    //                 onClick={handleExportData}
    //                 startIcon={<FileDownload />}
    //                 variant="contained"
    //             >
    //                 Export All
    //             </Button>
    //             <Button
    //                 disabled={table.getPrePaginationRowModel().rows.length === 0}
    //                 //export all rows, including from the next page, (still respects filtering and sorting)
    //                 onClick={() =>
    //                     handleExportRows(table.getPrePaginationRowModel().rows)
    //                 }
    //                 startIcon={<FileDownload />}
    //                 variant="contained"
    //             >
    //                 Export Rows
    //             </Button>
    //         </Box>
    //     ),
    //     renderToolbarInternalActions: ({ table }) => (
    //         <Box>
    //             <IconButton
    //                 onClick={() => {
    //                     window.print();
    //                 }}
    //             >
    //                 <Print />
    //             </IconButton>
    //             <MRT_ToggleGlobalFilterButton table={table} />
    //             <MRT_ToggleFiltersButton table={table} />
    //             <MRT_ShowHideColumnsButton table={table} />
    //             <MRT_ToggleDensePaddingButton table={table} />
    //             <MRT_ToggleFullScreenButton table={table} />
    //             {/* <MRT_GlobalFilterTextField table={table} /> */}
    //         </Box>
    //     ),
    // }, [])

    return (
        <MaterialReactTable
            // elevation={0}
            // variant='outlined'
            data={data}
            columns={columns}
            // enableColumnResizing
            initialState={{
                showColumnFilters: true,
                columnVisibility: columnVisibility,
                // pagination: { pageSize: 10, pageIndex:1 }
            }}
            enableRowActions={deleteUrl !== null || detailUrl !== null || editUrl !== null}
            // onColumnFiltersChange={setColumnFilters} 
            positionActionsColumn='first'
            displayColumnDefOptions={{
                'mrt-row-actions': {
                    header: '', //change header text
                    size: 50, //make actions column wider
                    alignment: 'left'
                },
            }}
            renderRowActionMenuItems={({ row }) => [
                detailUrl &&
                <MenuItem
                    key={0}
                    component={Link} to={pathname + detailUrl} state={row.original}
                    sx={{ m: 0 }}
                    disabled={detailUrl === null}
                >
                    <ListItemIcon>
                        <Info color={detailUrl ? 'info' : 'disabled'} />
                    </ListItemIcon>
                    Detail
                </MenuItem>,
                editUrl &&
                <MenuItem
                    key={1}
                    component={Link} to={pathname + editUrl} state={row.original}
                    sx={{ m: 0 }}
                    disabled={editUrl === null}
                >
                    <ListItemIcon>
                        <Edit color={editUrl ? 'primary' : 'disabled'} />
                    </ListItemIcon>
                    Edit
                </MenuItem>,
                deleteUrl &&
                <MenuItem
                    key={2}
                    onClick={() => {
                        // View profile logic...
                        hapusData(deleteField, row.original[deleteField], deleteUrl);
                    }}
                    sx={{ m: 0 }}
                    disabled={deleteUrl === null}
                >
                    <ListItemIcon>
                        <Delete color={deleteUrl ? 'error' : 'disabled'} />
                    </ListItemIcon>
                    Hapus
                </MenuItem>,
            ]}
            muiTableHeadCellProps={{
                sx: {
                    fontSize: '0.875rem !important',
                }
            }}
            muiTableBodyCellProps={{
                sx: {
                    fontWeight: 'normal',
                    fontSize: '0.875rem !important',
                },
            }}
            muiTablePaginationProps={{
                rowsPerPageOptions: [10, 20, 50, { label: 'All', value: data.length }],
                showFirstButton: true,
                showLastButton: true,
            }}
            {...props}
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        onClick={handleExportData}
                        startIcon={<FileDownload />}
                        variant="contained"
                    >
                        Export All
                    </Button>
                    <Button
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        //export all rows, including from the next page, (still respects filtering and sorting)
                        onClick={() =>
                            handleExportRows(table.getPrePaginationRowModel().rows)
                        }
                        startIcon={<FileDownload />}
                        variant="contained"
                    >
                        Export Rows
                    </Button>
                </Box>
            )}
            renderToolbarInternalActions={({ table }) => (
                <Box>
                    <IconButton
                        onClick={() => {
                            window.print();
                        }}
                    >
                        <Print />
                    </IconButton>
                    <MRT_ToggleGlobalFilterButton table={table} />
                    <MRT_ToggleFiltersButton table={table} />
                    <MRT_ShowHideColumnsButton table={table} />
                    <MRT_ToggleDensePaddingButton table={table} />
                    <MRT_ToggleFullScreenButton table={table} />
                    {/* <MRT_GlobalFilterTextField table={table} /> */}
                </Box>
            )}
        />
    )
}

export default MyTable