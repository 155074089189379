import { ArrowBack, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Backdrop, Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, MenuItem, Select, Tooltip } from '@mui/material'
import axios from 'axios'
import SoftBox from 'components/SoftBox'
import SoftInput from 'components/SoftInput'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { postSubmit } from 'global/postFunc'
import { serverUrl } from 'global/variable'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'

const TambahKorcam = () => {
    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));

    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(false)
    
    const [kecamatans, setKecamatans] = useState([])
    const [desas, setDesas] = useState([])

    const [formData, setFormData] = useState({
        nama: '',
        nik: '',
        no_hp: '',
        id_kec: '',
        nama_kec: '',
        id_kel: '',
        nama_kel: '',
        status: 'Korcam'
    })

    useEffect(() => {
        console.log(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    const submit = (event) => {
        event.preventDefault();
        if (formData.nik.length !== 16) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "NIK harus 16 digit",
              });
              return
        }
        setLoading(true)
        addReq()
        postSubmit(
            '/entry/korcam/insert',
            formData,
            decryptedUser,
            afterSubmit,
            (error) => {
                setLoading(false)
                console.log(error);
            }
        )
    }

    const afterSubmit = () => {
        setLoading(false)
        navigate(-1)
    }

    const handleChangeDesa = (event) => {
        if (event.target.value !== '') {
            let resultObject = desas.find(x => x.id_desa === event.target.value)
            // reqDpt(event.target.value)
            setFormData({ ...formData, id_kel: event.target.value, nama_kel: resultObject.nama_desa })
            console.log(resultObject);
        }
    };

    useEffect(() => {
        getKec()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getKec = async () => {
        addReq()
        const kec = await axios.get(serverUrl + '/kecamatan');

        setKecamatans(kec.data)
    }

    const handleChangeKec = (event) => {
        // setDesas([])
        if (event.target.value === '') {
            setFormData({ ...formData, id_kec: '', nama_kec: '' })
        }
        else {
            let resultObject = kecamatans.find(x => x.id_kec === event.target.value)
            setFormData({ ...formData, id_kec: event.target.value, nama_kec: resultObject.nama_kec })
            getDesa(event.target.value)
            // event.target.value !== '' && getDesa(event.target.value)
        }
    };

    const getDesa = async (params) => {
        addReq()
        const data = await axios.get(serverUrl + '/desa/' + params);
        setDesas(data.data)
        // console.log(data);
    }

    return (
        <SoftBox mb={3}>
            <Grid container spacing={3} justifyContent='center' py={5}>
                <Grid item xs={12} sm={12} md={9}>
                    <Card >
                        <CardHeader
                            title={
                                <SoftTypography variant='h6'>Kembali</SoftTypography>
                            }
                            avatar={
                                <Tooltip title="Kembali">
                                    <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                </Tooltip>
                            }
                        />
                        <CardContent component='form' onSubmit={submit}>
                            {/* <Grid container justifyContent='center' pb={2}>
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        <AlertTitle>Penting</AlertTitle>
                                        Bagi Korcam yang tidak ada NIK, maka NIK diganti menjadi "korcam_nama_kecamatan". Contoh korcam_baula
                                    </Alert>
                                </Grid>
                            </Grid> */}
                            <Grid container justifyContent='center' direction='column'>
                                <Grid item xs={12} sm={12} lg={6} mb={2}>
                                    <SoftBox mb={1} ml={0.5}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Pilih Kecamatan
                                        </SoftTypography>
                                    </SoftBox>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue=''
                                        value={formData.id_kec || ""}
                                        label="Kecamatan"
                                        onChange={handleChangeKec}
                                        required
                                    >
                                        <MenuItem value=''><em>Pilih Kecamatan</em></MenuItem>
                                        {
                                            kecamatans.map((kec) => (
                                                <MenuItem key={kec.id_kec} value={kec.id_kec}>{kec.nama_kec}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6} mb={2}>
                                    <SoftBox mb={1} ml={0.5}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Alamat
                                        </SoftTypography>
                                    </SoftBox>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue=''
                                        value={formData.id_kel || ''}
                                        label="Desa"
                                        onChange={handleChangeDesa}
                                        required
                                    >
                                        <MenuItem value=''><em>Pilih Desa</em></MenuItem>
                                        {
                                            desas.map((des) => (
                                                <MenuItem key={des.id_desa} value={des.id_desa}>{des.nama_desa}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <SoftBox mb={2} justifyContent="center">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                NIK
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            type="text"
                                            defaultValue={formData.nik}
                                            onChange={(event) => setFormData({ ...formData, nik: event.target.value })}
                                            autoComplete='off'
                                        />
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <SoftBox mb={2} justifyContent="center">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Nama
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            type="text"
                                            defaultValue={formData.nama}
                                            onChange={(event) => setFormData({ ...formData, nama: event.target.value })}
                                            autoComplete='off'
                                        />
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <SoftBox mb={2} justifyContent="center">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                No. HP
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            type="text"
                                            defaultValue={formData.no_hp}
                                            onChange={(event) => setFormData({ ...formData, no_hp: event.target.value })}
                                            autoComplete='off'
                                            required
                                            inputProps={{ maxLength: 12 }}
                                        />
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6}>
                                    <Grid container justifyContent='flex-end'>
                                        <Grid item>
                                            <SoftBox mb={1} ml={0.5}>
                                                <LoadingButton
                                                    loading={loading}
                                                    loadingPosition="start"
                                                    startIcon={<Save />}
                                                    variant="contained"
                                                    type='submit'
                                                >
                                                    Save
                                                </LoadingButton>
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        {
                            loading &&
                            (
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={loading}
                                // onClick={handleClose}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            )
                        }
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default TambahKorcam